import React from 'react';
import { connect } from 'react-redux';

// node
import Icon from '@mdi/react';
import { mdiHelpCircle } from '@mdi/js';

// Components
import Tooltip from '../Tooltip';

// utils
import { numberFormat } from '../../utils';

// styles
import { Summary, Price } from '../SummaryCart/style';

const tipoPagamentoCredito = 4;

const SummaryCart = ({
  summary,
  verificaMultiploPagamento,
  pagamentos,
  orders,
}) => {
  const isCreditApplied = verificaMultiploPagamento.multiplePayments;

  const paymentCredit =
    pagamentos &&
    pagamentos.find(
      pagamento => pagamento.fk_tipo_pagamento === tipoPagamentoCredito
    );
  const saldoOrder = parseFloat(paymentCredit && paymentCredit.vlr_pagamento);
  const summaryOrder = orders.order && orders.order.resumo.total;

  if (!summary) {
    return '';
  }

  function getTotal() {
    if (saldoOrder >= Math.ceil(summaryOrder* 100)/100) {
      return 0;
    }
    if (summaryOrder > saldoOrder && summary.cupom <= 0) {
      return summaryOrder - saldoOrder;
    } else if (summaryOrder > saldoOrder){
        return summaryOrder;
    }
  }

  function showDiscountCredit() {
    if (saldoOrder >= summaryOrder) {
      return summaryOrder;
    } else if (saldoOrder < summaryOrder) {
      return saldoOrder;
    }
  }

  return (
    <Summary>
      <ul>
        {summary.impressao ? (
          <li>
            <span>SubTotal</span>
            <span>R$ {numberFormat(summary.impressao)}</span>
          </li>
        ) : (
          ''
        )}
        {summary.acrescimo.valor ? (
          <li>
            <span>
              Acréscimo
              <Tooltip
                dataId={summary.acrescimo.tipo}
                text={summary.acrescimo.descricao}
                icon={<Icon path={mdiHelpCircle} size={0.8} />}
              />
            </span>
            <span>R$ {numberFormat(summary.acrescimo.valor)}</span>
          </li>
        ) : (
          ''
        )}
        {summary.acabamentos ? (
          <li>
            <span>Acabamentos</span>
            <span>R$ {numberFormat(summary.acabamentos)}</span>
          </li>
        ) : (
          ''
        )}
        {summary.servicos ? (
          <li>
            <span>Serviços Opcionais</span>
            <span>R$ {numberFormat(summary.servicos)}</span>
          </li>
        ) : (
          ''
        )}
        {summary.peso_total ? (
          <li>
            <span>Peso Total</span>
            <span>
              {numberFormat(summary.peso_total)} {summary.unidade_peso}
            </span>
          </li>
        ) : (
          ''
        )}
        {summary.entrega ? (
          <li>
            <span>Frete</span>
            <span>R$ {numberFormat(summary.entrega)}</span>
          </li>
        ) : (
          ''
        )}
        {summary.cupom ? (
          <li>
            <span>Desconto do cupom</span>
            <span>- R$ {numberFormat(summary.cupom)}</span>
          </li>
        ) : (
          ''
        )}
        {paymentCredit || isCreditApplied ? (
          <li>
            <span>Desconto do saldo da carteira</span>
            <span>
              - R$ {numberFormat(showDiscountCredit())}
            </span>
          </li>
        ) : (
          ''
        )}
      </ul>

      <Price>
        <span>Total</span>
        {paymentCredit || isCreditApplied ? (
          <span>R$ {numberFormat(getTotal())}</span>
        ) : (
          <span>R$ {numberFormat(summaryOrder)}</span>
        )}
      </Price>

      <a
        target="_blank"
        without
        rel="noopener noreferrer"
        href="https://instrucoes.fabricadolivro.com.br/perguntas-frequentes/como-contar-o-prazo-ate-receber-os-meus-produtos/"
      >
        Como calcular o prazo
      </a>
    </Summary>
  );
};

const mapStateToProps = state => {
  return {
    summary: state.cart.summaryCart,
    orders: state.orders,
    getSaldoPayment: state.payment.payments,
    getTypePayment: state.payment.payment,
    verificaMultiploPagamento: state.payment,
  };
};

export default connect(mapStateToProps, null)(SummaryCart);
