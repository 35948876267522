/**
 * Mensagens de retorno ao usuário
 */
export const SUCCESS = 'Os dados foram enviados com sucesso';
export const ERROR = 'Ocorreu um erro ao enviar os dados, tente novamente mais tarde';
export const CONNECTION_ERROR = 'Houve algum problema com a conexão, por favor tente mais tarde!';
export const LOGIN_SUCCESS = 'Login realizado com sucesso';
export const FORM_SUBMIT_BLANK = 'Preencha os dados do formulário para continuar!';
export const EMAIL_NEW_PASS = 'Verifique seu email para recuperar a senha';
export const SIGN_UP_SUCCESS = 'Cadastro realizado com sucesso.';
export const ADDRESS_CREATE_SUCCESS = 'Endereço cadastrado com sucesso';
export const UPDATE_SUCCESS = 'Dados alterados com sucesso.';
export const UPDATE_PRIVACY_SUCCESS = 'Privacidade Alterada com sucesso.';
export const PRODUCT_NOT_FOUND = 'Não foi possível encontrar o produto indicado';
export const MESSAGE_SUCCESS = 'Mensagem enviada com sucesso!';
export const EMAIL_BUDGET = 'Orçamento enviado para o email informado!';
export const ADD_ITEM_SUCCESS = 'Item adicionado ao carrinho!';

export const REMOVE_ITEM_ERROR = 'Não foi possível remover o item, por favor tente mais tarde!';
export const CHOOSE_STATE = 'Selecione um estado!';
export const CHOOSE_CITY = 'Selecione uma cidade!';
export const SELECT_DEPOSIT = 'Selecione uma opção de depósito!';
export const FINISH_ORDER_SUCCESS = 'Pedido finalizado com sucesso!';
export const CHOOSE_PAYMENT = 'Selecione uma forma de pagamento!';
export const CHOOSE_DELIVERY = 'Selecione uma opção de entrega!';
export const RECEIPT_SENDED = 'Comprovante enviado com sucesso!';
export const RECEIPT_NOT_SENDED = 'Envio o arquivo do comprovante para prosseguir!';
export const TERMS_OF_USE = 'É necessário estar de acordo com os termos de aceite!';


// credit card
export const CHANGE_NUMBER = 'Digite o Numero do Cartão';
export const CHOOSE_MONTH = 'Selecione o Mês';
export const CHOOSE_YEAR = 'Selecione o Ano';
export const CHOOSE_CVC = 'Digite o CVC';
export const INVALID_CVC = 'Digite o CVC correto!';
export const CHOOSE_PARCELS = 'Selecione o numero de parcelas';
export const CHOOSE_NAME_CARD = 'Digite o Nome do Titular';
export const INVALID_NUMBER = 'O Número do Cartão é inválido!';
export const INVALID_CARD = 'Cartão Inválido';


// files
export const EXTENSIONS_ERROR = 'Verifique as extensões dos arquivos para continuar';
export const REQUIRED_FILES = 'Envie todos os arquivos obrigatórios para prosseguir';
export const SEND_EDITOR_ERROR = 'Ocorreu um erro ao enviar os arquivos, tente novamente mais tarde.';
export const ANALYSE_EDITOR_ERROR = 'Ocorreu um erro na execução na análise dos arquivos. Entre em contato com a Central de Atendimento.';
export const AUTH_FILE = 'Por favor, preencha autorização';


// order
export const ORDER_NAME_SUCCESS = 'O nome do pedido foi salvo.';
export const ORDER_NAME_ERROR = 'Não foi possível salvar o nome do pedido.';
export const ORDER_OBSNF_SUCCESS = 'As observações da Nota Fiscal foram salvas.';
export const ORDER_OBSNF_ERROR = 'Não foi possível salvar as observações.';
export const CANCEL_ORDER_ERROR = 'Não foi possível cancelar o pedido';
export const ACCEPT_WITHDRAWAL = 'Para prosseguir, confirme os dados referente ao responsável de retirada no balcão';
export const VERIFY_WITHDRAWAL = 'Para prosseguir, confirme que a encomenda será verificada no ato da retirada';
export const ERROR_REQUEST_RESEND_FILES = 'Não foi possível solicitar o reenvio, tente novamente mais tarde';


// Message
export const TYPE_A_MESSAGE = 'Por favor, digite uma descrição da mensagem!';
export const TYPE_A_CASE = 'Por favor, digite uma descrição do chamado!';
export const CHOOSE_RATING ='Por favor, informe o nível de satisfação com o atendimento!';
export const FINALIZE_REGISTER = 'Por favor, termine de preencher seu cadastro!';

//Cart
export const APPLY_CUPOM_SUCCESS = 'Cupom aplicado com sucesso!';
export const APPLY_CUPOM_ERROR = 'Erro ao aplicar cupom, tente novamente.';
export const REMOVE_ITEM_SUCCESS = 'Item removido do carrinho!';
export const EMPTY_CART = 'Você não possui itens adicionados no carrinho!'; 
export const REMOVE_ITEMS_CART = 'Carinho limpo com sucesso!';
