import React from 'react';
import Icon from '@mdi/react';
import { mdiAlertCircle } from '@mdi/js';

const StatusItem = ({ data }) => {

    //const pkStatusPedidoItem = 4;
    const descStatusPedidoItem = "Arquivo C/ Problemas";

    return (
        <div>
            
            {data.statusPedidoItem.desc_status_pedido_item === descStatusPedidoItem && (
                <div className="status-container">
                    <div style={{ textAlign: 'left' }}>ATENÇÃO: VERIFICAR SEU ARQUIVO COM PROBLEMAS EM OBSERVAÇÕES.</div>
                    <div className="warning" style={{ marginLeft: '6em' }}>
                        <span className="status-label">STATUS:</span>
                        <Icon path={mdiAlertCircle} text={'Detalhes'} size={0.8} color={'#ff0606'} style={{ verticalAlign: 'middle' }} />
                        {descStatusPedidoItem}
                    </div>
                </div>
            )}
        </div>
    );
};

export default StatusItem;
