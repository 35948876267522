import React from 'react';
import { Field } from 'redux-form';

// Validate
import renderField from '../../../../formValidate/renderField';
// hocs
import CardFilter from '../../../../hocs/CardFilter';
// Styles
import { CapaRow, Col, Row } from '../../../../styles/align';
import { Label, Wrapper } from '../../../../styles/forms';
import { TitleCards } from '../../../../styles/titles';
// Utils
//import { numberFormat } from '../../../../utils';
// Components
import Tooltip from '../../../Tooltip';
import { Colors } from './style';

const BookOptions = ({
  data,
  onChangeSubmit,
  updateFinishBlocked,
  finishesBlocked,
}) => {
  const {
    papel,
    acabamento,
    acabamento_incluso,
    acabamento_capa,
    //acabamentos_opcionais,
    lados_da_impressao_da_capa,
    dados_produto,
  } = data;
  
  return (
    <>
    <TitleCards>Tipo de papel</TitleCards>
      <Row>
        {papel
          ? papel.opcoes.map((item, i) => {
              return (
                <Wrapper key={i}>
                  <Field
                    name={'papel'}
                    id={`papel[${i}]`}
                    type={'radio'}
                    typefield={'radio'}
                    onChange={(event, newValue, previousValue, name) =>
                      onChangeSubmit(event, newValue, previousValue, name)
                    }
                    component={renderField}
                    value={`${item.pk_dependencia_atributo}`}
                  />
                  <Label htmlFor={`papel[${i}]`}>
                    {item.texto_dependencia_atributo}
                  </Label>
                </Wrapper>
              );
            })
          : ''}
      </Row>
    {dados_produto.fk_sub_tipo_produto === 61 ? (
      <>
        <TitleCards>Cores de impressão</TitleCards>
           <Colors> 
             <p>Colorido</p>
           </Colors> 
      </>
    ) : (
      '' 
    )}

      <TitleCards>Revestimento da Capa</TitleCards>
      <Row>
        {acabamento
          ? acabamento.opcoes.map((item, i) => {
              return (
                <Wrapper key={i}>
                  <Field
                    name={'acabamento'}
                    id={`acabamento[${i}]`}
                    type={'radio'}
                    typefield={'radio'}
                    onChange={(event, newValue, previousValue, name) =>
                      onChangeSubmit(event, newValue, previousValue, name)
                    }
                    component={renderField}
                    value={`${item.pk_dependencia_atributo}`}
                  />
                  <Label htmlFor={`acabamento[${i}]`}>
                    {item.texto_dependencia_atributo}
                  </Label>
                </Wrapper>
              );
            })
          : ''}
      </Row>
      <CapaRow>
        <TitleCards>Opções para Capa</TitleCards>
        <Tooltip
          dataId={'capa_id'}
          text={`<strong>Colagem:</strong>
          <br />
          <p>Processo de fixação do miolo realizado durante a encadernação do impresso, no qual é
          aplicada uma camada de cola à quente unindo todas as páginas, conferindo melhor
          acabamento para lombadas quadradas.        
          </p>
          <br />
          <strong>Costura:</strong>
          <br />
          <p>Processo de fixação do miolo indicado para produtos com capa dura, no qual é realizada uma
          costura. Ideal para lombadas quadradas, fornecendo
          maior resistência e durabilidade ao material.       
          </p>`}
          size={0.8}
        />
      </CapaRow>
      <Row margin={'0 0 2rem 0'}>
        {acabamento_incluso
          ? acabamento_incluso.opcoes.map((item, i) => {
              return (
                <Wrapper key={i}>
                  <Field
                    name={'acabamento_incluso'}
                    id={`acabamento_incluso[${i}]`}
                    type={'radio'}
                    typefield={'radio'}
                    onChange={(event, newValue, previousValue, name) =>
                      onChangeSubmit(event, newValue, previousValue, name)
                    }
                    component={renderField}
                    value={`${item.pk_dependencia_atributo}`}
                  />
                  <Label htmlFor={`acabamento_incluso[${i}]`}>
                    {item.texto_dependencia_atributo}
                  </Label>
                </Wrapper>
              );
            })
          : ''}
      </Row>
      {lados_da_impressao_da_capa && lados_da_impressao_da_capa.opcoes.length > 1 ? (
        <>
          <TitleCards>Lados da Impressão da Capa</TitleCards>
          <Row>
            {lados_da_impressao_da_capa.opcoes.map((item, i) => {
              return (
                <Wrapper key={i}>
                  <Field
                    name={'lados_da_impressao_da_capa'}
                    id={`lados_da_impressao_da_capa[${i}]`}
                    type={'radio'}
                    typefield={'radio'}
                    component={renderField}
                    value={`${item.pk_dependencia_atributo}`}
                    onChange={(event, newValue, previousValue, name) =>
                      onChangeSubmit(event, newValue, previousValue, name)
                    }
                  />
                  <Label htmlFor={`lados_da_impressao_da_capa[${i}]`}>
                    {item.texto_dependencia_atributo}
                  </Label>
                </Wrapper>
              );
            })}
          </Row>
        </>
      ) : (
        ''
      )}
      <Col>
        {acabamento_capa
          ? acabamento_capa.opcoes.map((item, i) => {
              return (
                <Wrapper key={i}>
                  <Field
                    name={'acabamento_capa'}
                    id={`acabamento_capa[${i}]`}
                    type={'radio'}
                    typefield={'radio'}
                    component={renderField}
                    onChange={(event, newValue, previousValue, name) =>
                      onChangeSubmit(event, newValue, previousValue, name)
                    }
                    value={`${item.pk_dependencia_atributo}`}
                  />
                  <Label htmlFor={`acabamento_capa[${i}]`}>
                    {item.texto_dependencia_atributo}
                  </Label>
                </Wrapper>
              );
            })
          : ''}
      </Col>

      {/* os Acabamentos Opcionais estao na page filterBook */}
      {/* {acabamentos_opcionais.length > 0 ? (
        <>
          <TitleCards>Acabamentos Opcionais</TitleCards>
        >
            {acabamentos_opcionais.map((item, i) => {
              return (
                <Wrapper key={i}>
                  <Field
                    name={`acabamento_opcional[${item.pk_acabamento}]`}
                    id={`acabamento_opcional[${item.pk_acabamento}]`}
                    type={'checkbox'}
                    typefield={'checkbox'}
                    component={renderField}
                    disabled={finishesBlocked.includes(item.pk_acabamento)}
                    onChange={() => updateFinishBlocked(item.pk_acabamento)}
                    value={`${item.pk_acabamento}`}
                  />
                  <Label htmlFor={`acabamento_opcional[${item.pk_acabamento}]`}>
                    <span>{item.desc_acabamento}</span>
                    <span>R$ {numberFormat(item.valor_acabamento, 2)}</span>
                  </Label>
                </Wrapper>
              );
            })}
          >
        </>
      ) : (
        ''
      )} */}
    </>
  );
};

export default CardFilter(BookOptions);
