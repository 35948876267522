import React from 'react';
import ImageWhats from '../../assets/images/whatsapp-icon.png';
import { ContainerButton, Icon } from './style';

export const WhatsApp = () => {
  return (
    <ContainerButton>
      <a
        href={`http://api.whatsapp.com/send?1=pt_BR&phone=554196119037`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon src={ImageWhats} alt="" title="" />
      </a>
    </ContainerButton>
  );
};
