import gtag from 'ga-gtag';
import React, { Component } from 'react';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, reduxForm } from 'redux-form';
import { numberFormat } from '../../utils';
import { PaymentTypes } from '../../enums/payment';

// node
import { mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';

// Components
import Loader from '../../components/Loader';
import LoaderForm from '../../components/Loader/loaderForm';
import Payment from '../../components/Payments';
import SummaryCart from '../../components/SummaryCart';
import Terms from '../../components/Terms';

// partials
import Delivery from './partials/Delivery';
import Itens from './partials/Itens';
import FileVerification from './partials/FileVerification';

// actions
import { fetchAddresses } from '../../actions/address';
import {
  balconySelected,
  clearCart,
  clearCartSummary,
  clearDeliveryOptions,
  deliverySelected,
  fetchCart,
  fetchDeliveryOptions,
  isSubmitForm,
  numberItens,
  resetShippingContact,
  resetWithdrawal,
  setCupom,
  setSummary,
  updateCart,
} from '../../actions/cart';
import {
  modalRegister,
  modalResendFiles,
  modalShippingContact,
  modalWithdrawal,
} from '../../actions/modals';
import {
  clearDataCard,
  clearHashCard,
  clearPayment,
  clearPayments,
  cleanTwoCards,
  hashCard,
  setPayments,
} from '../../actions/payment';

// services
import {
  applyCupom,
  cartClear,
  finishOrder,
  getToken,
  removeToken,
  saveOrderName,
  saveOrderObservationNF,
} from '../../services/cart';
import { validateCreditCard } from '../../services/payment';

// utils
import * as constMessage from '../../utils/constMessage';
import { buildPayloadPaymentTwoCards } from '../../utils';

// Styles
import { Col, Row, TextAlign } from '../../styles/align';
import {
  BtnGroup,
  Button,
  ButtonPrimary,
  ButtonSecondary,
  Input,
  Label,
  Wrapper,
  Checkbox,
  Textarea,
} from '../../styles/forms';
import { TitleContainer, TitlePage } from '../../styles/titles';
import { CardAction, CartStyle, Loading, ModalCard } from './style';

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: '',
      loading: false,
      nome_pedido: '',
      observacoes: '',
      incluirInformacoes: false,
      beginCheckout: false,
    };
  }

  componentDidMount() {
    const token = getToken();

    this.props.fetchCart(token).then(result => {
      if (this.props.cart !== undefined) {
        this.onUpdate(this.props.cart);

        if (this.props.cart.carrinho.pedido.nome_pedido) {
          this.setState({
            nome_pedido: this.props.cart.carrinho.pedido.nome_pedido,
          });
        }
      } else {
        toast.error(constMessage.EMPTY_CART);
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      }
    });
    this.props.fetchAddresses()
    this.props.fetchDeliveryOptions(token).then(result => {
      if (
        this.props.deliveryOptions &&
        this.props.deliveryOptions.resumo.length
      ) {
        this.onUpdate({
          resumo: this.props.deliveryOptions.resumo,
          pagamento: this.props.deliveryOptions.pagamento,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if(!this.state.beginCheckout && prevProps.payment.tipo_pagamento !== this.props.payment.tipo_pagamento) {
      this.setState({beginCheckout: true})
      TagManager.dataLayer({
        dataLayer: {
          event: 'begin_checkout',
          ecommerce: {
            checkout: {
              actionField: { step: 1 }, // Etapa do processo de checkout
            },
          },
        },
      });
    }
  }

  onUpdate = ({
    resumo,
    pagamento,
    hash,
    clearPayment,
    clearDelivery,
    cleanTwoCards,
  }) => {
    if (clearPayment) {
      this.props.clearPayment();
      this.props.clearPayments();
    }

    if (pagamento) {
      this.props.setPayments(pagamento);
    }

    if (resumo) {
      this.props.setSummary(resumo);
    }

    if (hash) {
      this.props.hashCard(hash);
    }

    if (cleanTwoCards) {
      this.props.cleanTwoCards();
    }

    if (clearDelivery) {
      this.props.balconySelected({
        balconySelected: false,
        balconyType: false,
      });
      this.props.deliverySelected({
        deliverySelected: false,
        deliveryType: false,
      });
      this.props.clearDeliveryOptions();
    }
  };

  componentWillUnmount() {
    this.props.clearCart();
    this.props.clearDeliveryOptions();
    this.props.clearPayment();
    this.props.clearCartSummary();
    this.props.clearPayments();
    this.props.resetShippingContact();
    this.props.resetWithdrawal();
    this.props.isSubmitForm(false);
    this.props.clearHashCard();
    this.onUpdate({ clearDelivery: true });
  }

  renderOrderName = () => {
    return (
      <CardAction>
        <span>Nome do Pedido</span>
        <Input
          onChange={e => this.setState({ nome_pedido: e.target.value })}
          value={this.state.nome_pedido}
        />
        <BtnGroup>
          <ButtonPrimary onClick={this.handleSaveOrderName}>
            Salvar
          </ButtonPrimary>
        </BtnGroup>
      </CardAction>
    );
  };

  renderObservation = () => {
    return (
      <CardAction>
        <Wrapper>
          <Checkbox
            id={'informacoesNF'}
            onChange={e => this.handleCheckboxChange(e)}
            checked={this.state.incluirInformacoes}
          />
          <span></span>
          <Label htmlFor="informacoesNF">
            Deseja incluir informações na sua Nota Fiscal?
          </Label>
          <ModalCard>
            {this.state.incluirInformacoes && (
              <Wrapper>
                <Textarea
                  id="observacoes"
                  name="observacoes"
                  value={this.state.observacoes}
                  onChange={this.handleObservacoesChange}
                  placeholder="Inclua as observações para a Nota Fiscal..."
                ></Textarea>
                <BtnGroup>
                  <ButtonPrimary onClick={this.handleSaveObservationNF}>
                    Salvar
                  </ButtonPrimary>
                </BtnGroup>
              </Wrapper>
            )}
          </ModalCard>
        </Wrapper>
      </CardAction>
    );
  };

  renderCupom = () => {
    const { cupom, cart } = this.props;

    return (
      <CardAction>
        <Col>
          <Row>
            <span>Cupom de Desconto</span>
            <Input
              onChange={e => this.props.setCupom({ cupom: e.target.value })}
              value={cupom}
            />
            <BtnGroup>
              <ButtonPrimary onClick={this.handleApplyCupom}>
                Aplicar
              </ButtonPrimary>
            </BtnGroup>
          </Row>
          {cart.carrinho.cupom ? (
            <Row>
              <p>{cart.carrinho.cupom.mensagem}</p>
            </Row>
          ) : (
            ''
          )}
        </Col>
      </CardAction>
    );
  };

  handleApplyCupom = async () => {
    const response = await applyCupom(this.props.cupom);
    if (response.status === 200) {
      this.props.updateCart(response.data);

      this.onUpdate({
        resumo: response.data.data.resumo,
        pagamento: response.data.data.pagamento,
        clearPayment: true,
      });
      toast.success(response.data.message);
    } else {
      toast.error(constMessage.APPLY_CUPOM_ERROR);
    }
  };

  handleSaveOrderName = async () => {
    const response = await saveOrderName(this.state.nome_pedido);
    if (response.status === 200) {
      toast.success(constMessage.ORDER_NAME_SUCCESS);
    } else {
      toast.error(constMessage.ORDER_NAME_ERROR);
    }
  };

  handleSaveObservationNF = async () => {
    const response = await saveOrderObservationNF(this.state.observacoes);
    if (response.status === 200) {
      toast.success(constMessage.ORDER_OBSNF_SUCCESS);
    } else {
      toast.error(constMessage.ORDER_OBSNF_ERROR);
    }
  };

  onRedirect = url => {
    this.props.history.push(url);
  };

  handleSubmit = async () => {
    let dataPayment = {};
    const {
      multiplePayments,
      payment,
      userLogged,
      clearDataCard,
      twoCards,
    } = this.props;

    if (!userLogged.cpf_cnpj_usuario) {
      toast.warn(constMessage.FORM_SUBMIT_BLANK);
      this.props.modalRegister();
      return false;
    }

    if (this.props.typeDelivery === '1') {
      if (
        this.props.deliverySelected === false ||
        this.props.deliveryType === false
      ) {
        toast.warn(constMessage.CHOOSE_DELIVERY);
        return false;
      }
    } else {
      if (
        this.props.balconySelected === false ||
        this.props.balconyType === false
      ) {
        toast.warn(constMessage.CHOOSE_DELIVERY);
        return false;
      }
      if (!this.props.withdrawalDone) {
        this.props.modalWithdrawal();
        this.props.isSubmitForm(true);
        return false;
      }
    }

    if (payment.tipo_pagamento === '') {
      toast.warn(constMessage.CHOOSE_PAYMENT);
      return false;
    }

    switch (payment.tipo_pagamento) {
      case '1':
        if (!payment.pagamento) {
          toast.warn(constMessage.SELECT_DEPOSIT);
          return false;
        }
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: payment.pagamento,
        };
        break;
      case PaymentTypes.CREDIT_CARD:
        const validateResult = validateCreditCard(payment);
        if (validateResult.status !== 'success') {
          toast[validateResult.status](validateResult.message);
          clearDataCard();
          return false;
        }
        dataPayment = {
          ...payment,
          pagamento: payment.dependency, // precisa verificar onde tem que setar isso no redux
        };
        break;
      case '4':
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: '13',
        };
        break;
      case '6':
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: '22', // precisa verificar onde tem que setar isso no redux
        };
        break;
      case '10':
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: '32', // precisa verificar onde tem que setar isso no redux
        };
        break;
      case '13':
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: '44', // precisa verificar onde tem que setar isso no redux
        };
        break;
      // no pagamento, só add as infos que o back vai precisar
      // Mudar pra um numero inteiro (verificar qual numero colocar, Cleber falou)
      case 'twoCards':
        const twoCardsValidData =
          validateCreditCard(twoCards.firstCard) &&
          validateCreditCard(twoCards.secondCard);
        if (twoCardsValidData.status !== 'success') {
          toast[twoCardsValidData.status](twoCardsValidData.message);
          return false;
        }
        dataPayment = {
          // tipo_pagamento: payment.tipo_pagamento,
          // verificar qual numero colocar (talvez vai ser necessário criar tabela no banco, Cleber falou)
          tipo_pagamento: 3,
          ...buildPayloadPaymentTwoCards({
            primeiroCartao: twoCards.firstCard,
            segundoCartao: twoCards.secondCard,
          }),
        };
        break;
      default:
        toast.warn(constMessage.CHOOSE_PAYMENT);
        break;
    }

    if (multiplePayments) {
      dataPayment = {
        ...dataPayment,
        tipo_pagamento_credito: '4',
        pagamento_credito: '13',
      };
    }

    let retorna = false;
    this.props.cart.carrinho.itens.forEach(item => {
      if (item.pedidoItemArquivo === undefined) {
        retorna = true;
      }
    });
    if (retorna === true) {
      this.props.modalResendFiles();
      return false;
    }

    if (!this.props.checkedTerms) {
      // As 2 caixas de termos de aceite devem estar marcadas
      toast.warn(constMessage.TERMS_OF_USE);
      return false;
    }

    if (!this.props.shippingContactDone) {
      this.props.modalShippingContact();
      this.props.isSubmitForm(true);
      return false;
    }

    dataPayment = {
      ...dataPayment,
      observacoes: this.state.observacoes,
    };

    const response = await finishOrder(dataPayment);
    console.log(response, 'response do carrinho');

    if (response.status === 200) {
      toast.success(constMessage.FINISH_ORDER_SUCCESS);

      let products = [];

      const tagManagerArgs = items => {
        let product = items.map(item => ({
          item_name: item.produto.desc_categoria,
          item_id: item.produto.pk_produto,
          price: item.vlr_total_item,
          item_brand: '',
          quantity: item.qtd_pedido_item,
          item_category: item.produto.fk_categoria,
        }));

        return {
          dataLayer: {
            event: 'purchase',
            ecommerce: {
              purchase: {
                transaction_id: response.data.data.pk_pedido,
                affiliation: 'Fábrica do Livro',
                value: numberFormat(this.props.cart.resumo.total),
                shipping: numberFormat(this.props.cart.resumo.entrega),
                currency: 'BRL',
                coupon: '',
                items: product,
              },
            },
          },
        };
      };

      TagManager.dataLayer(tagManagerArgs(this.props.cart.carrinho.itens));

      // Event snippet for Transações conversion page
      gtag('event', 'conversion', {
        send_to: 'AW-705761160/TN30CM6eirEBEIifxNAC',
        value: this.props.cart.resumo.total,
        currency: 'BRL',
        transaction_id: response.data.data.pk_pedido,
      });

      // Configuração Facebook Pixel
      const options = {
        autoConfig: true, // set pixel's autoConfig.
        debug: false, // enable logs
      };

      ReactPixel.init('400964964142464', options);

      ReactPixel.pageView();

      let productsData = products.map(product => {
        return {
          id: product.id,
          quantity: product.quantity,
          item_price: product.price,
        };
      });

      ReactPixel.track('Purchase', {
        value: this.props.cart.resumo.total,
        currency: 'BRL',
        contents: productsData,
        content_type: 'product',
      });
      // Fim configuração Facebook Pixel

      this.props.history.push(`/sucesso/${response.data.data.pk_pedido}`);
      removeToken();
      this.props.numberItens(0);
    } else {
      toast.error(constMessage.CONNECTION_ERROR);
    }
  };

  toggleLoading = () => {
    this.setState({ loading: !this.state.loading });
  };

  // Limpar carrinho
  handleClearCart = () => {
    this.toggleLoading();
    const token = getToken();
    cartClear().then(response => {
      if (response.status === 200) {
        this.props.fetchCart(token);
        this.toggleLoading();
        toast.success(constMessage.REMOVE_ITEMS_CART);
        window.location.href = '/';
      } else {
        toast.error('Erro ao limpar carrinho');
      }
    });
  };

  handleCheckboxChange = event => {
    const isChecked = event.target.checked;
    this.setState({
      incluirInformacoes: isChecked,
      observacoes: isChecked ? this.state.observacoes : '',
    });
  };

  handleObservacoesChange = event => {
    this.setState({ observacoes: event.target.value });
  };

  render() {
    const { cart, handleSubmit, submitting } = this.props;

    if (submitting || !cart) {
      return (
        <CartStyle>
          <Col>
            <Loader />
          </Col>
        </CartStyle>
      );
    }

    if (!this.props.cart.carrinho.itens) {
      return (
        <CartStyle>
          <TextAlign>Você não possui itens adicionados no carrinho!</TextAlign>
        </CartStyle>
      );
    }

    return (
      <CartStyle>
        {this.state.loading ? (
          <Loading>
            <Loader />
          </Loading>
        ) : (
          ''
        )}
        <Col>
          <TitleContainer>
            <TitlePage>
              <span>Meu Carrinho</span>
            </TitlePage>
            <span>
              <Button onClick={this.handleClearCart}>
                <Icon
                  path={mdiTrashCanOutline}
                  text={'LimparCarrinho'}
                  size={0.8}
                />
                Limpar Carrinho
              </Button>
            </span>
          </TitleContainer>

          <Itens
            onRedirect={this.onRedirect}
            onUpdate={this.onUpdate}
            toggleLoading={this.toggleLoading}
          />
          <FileVerification />

          <Row>
            <Delivery
              toggleLoading={this.toggleLoading}
              onUpdate={this.onUpdate}
            />
            <Col>
              <SummaryCart />
              {this.renderCupom()}
              {this.renderOrderName()}
              {this.renderObservation()}
            </Col>
          </Row>
          <Payment />
          <Terms />
          <Form onSubmit={handleSubmit(this.handleSubmit)}>
            <BtnGroup>
              <Link to={'/'}>
                <ButtonSecondary>Continuar Comprando</ButtonSecondary>
              </Link>
              <ButtonPrimary type={'submit'} disabled={submitting} secondary>
                Finalizar Compra{submitting ? <LoaderForm /> : ''}
              </ButtonPrimary>
            </BtnGroup>
          </Form>
        </Col>
      </CartStyle>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart.data,
    deliveryOptions: state.cart.deliveryOptions,
    payment: state.payment.payment,
    typeDelivery: state.cart.typeDelivery,
    deliverySelected: state.cart.deliverySelected,
    balconySelected: state.cart.balconySelected,
    multiplePayments: state.payment.multiplePayments,
    withdrawalDone: state.cart.withdrawalDone,
    shippingContactDone: state.cart.shippingContactDone,
    balconyType: state.cart.balconyType,
    deliveryType: state.cart.deliveryType,
    cupom: state.cart.cupom,
    userLogged: state.user.user,
    checkedTerms: state.cart.produtosChecked && state.cart.envioArquivosChecked,
    twoCards: state.payment.twoCards,
  };
};

Cart = reduxForm({
  form: 'cart',
})(Cart);

Cart = connect(mapStateToProps, {
  fetchCart,
  modalWithdrawal,
  fetchAddresses,
  fetchDeliveryOptions,
  clearCart,
  clearDeliveryOptions,
  clearPayment,
  clearDataCard,
  setPayments,
  hashCard,
  setSummary,
  numberItens,
  clearCartSummary,
  clearPayments,
  cleanTwoCards,
  modalShippingContact,
  modalRegister,
  resetShippingContact,
  resetWithdrawal,
  isSubmitForm,
  clearHashCard,
  deliverySelected,
  balconySelected,
  setCupom,
  updateCart,
  modalResendFiles,
})(Cart);

export default Cart;
