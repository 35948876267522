import React, { Component } from 'react';
import { connect } from 'react-redux';

// Node
import { toast } from 'react-toastify';

// hocs
import Modal from '../../hocs/Modal';

// components
import Payments from '../../components/Payments';
import Loader from "../../components/Loader"

// actions
import { fetchOrder } from '../../actions/orders';
import { clearPayment, clearDataCard } from '../../actions/payment';
import { modalPayment } from '../../actions/modals';

// services
import { changePayment } from '../../services/cart';
import { validateCreditCard } from '../../services/payment';

// utils
import {
  CONNECTION_ERROR,
  CHOOSE_PAYMENT,
  SELECT_DEPOSIT,
  SUCCESS,
} from '../../utils/constMessage';

// styles
import { ModalPayment } from './style';
import { BtnGroup, Button } from '../../styles/forms';

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }
  }

  componentWillUnmount() {
    this.props.clearPayment();
  }

  handleSubmitPayment = async () => {
    this.setState({ loading: true });
    let dataPayment = {};
    const { pk_pedido } = this.props.order;
    const { payment, clearDataCard } = this.props;

    if (payment.tipo_pagamento === '') {
      toast.warn(CHOOSE_PAYMENT);
      return false;
    }
    switch (payment.tipo_pagamento) {
      case '1':
        if (!payment.pagamento) {
          toast.warn(SELECT_DEPOSIT);
          return false;
        }
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: payment.pagamento,
        };
        break;
      case '3':
        const validateResult = validateCreditCard(payment);
        if (validateResult.status !== 'success') {
          toast[validateResult.status](validateResult.message);
          clearDataCard();
          setTimeout(() => {
            this.setState({ loading: false });
          }, 1000)
          return false;
        }
        dataPayment = {
          ...payment,
          pagamento: `${payment.dependency}`, // precisa verificar ontem tem que setar isso no redux
        };
        break;
      case '10':
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: '32',
        };
        break;
      case '13':
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: '44',
        };
        break;
      default:
        dataPayment = {
          tipo_pagamento: payment.tipo_pagamento,
          pagamento: '22', // precisa verificar ontem tem que setar isso no redux
        };
        break;
    }

    const response = await changePayment(pk_pedido, dataPayment);

    if (response.status === 200) {
      
      toast.success(SUCCESS);
      this.props.modalPayment();
      this.props.fetchOrder(pk_pedido);
      this.setState({ loading: false });
    
    } else if (response.errors) {

      this.props.modalPayment();
      this.props.fetchOrder(pk_pedido);
      this.setState({ loading: false });
      toast.error(`${response.errors.message}. Aguarde um momento...`);

      setTimeout(() => {
        window.location.href = `/pedido/${pk_pedido}`
      }, 2000);

    } else {
      
      this.setState({ loading: false });
      toast.error(CONNECTION_ERROR);

    }
  };

  render() {
    return (
      <ModalPayment>
        {this.state.loading ? <Loader /> :
          <>
            <Payments />
            <BtnGroup>
              <Button onClick={this.props.modalPayment}>Cancelar</Button>
              <Button onClick={() => this.handleSubmitPayment()}>Enviar</Button>
            </BtnGroup>
          </>
        }
      </ModalPayment>
    );
  }
}

const mapStateToProps = state => {
  return {
    order: state.orders.order,
    payment: state.payment.payment,
  };
};

Payment = connect(mapStateToProps, { fetchOrder, clearPayment, modalPayment, clearDataCard })(
  Payment
);

Payment = Modal(Payment);

export default Payment;
