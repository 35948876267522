import styled from 'styled-components';

// Styles
import { Row, Col } from '../../styles/align';
import { Wrapper, Label, BtnGroup, Button } from '../../styles/forms';
import { TitlePage } from '../../styles/titles';
import {
  CreditCardStyle,
  PaymentItemContent,
  ContainerNumberCard,
  ListCards,
} from '../../components/Payments/style';
import { colors } from '../../styles/variables';

/**
 * Formata o Container da Modal de Pagamento
 * @extends {div} Tag div nativa do HTML
 *
 * Herda estilo para formatar o interior do Container
 * @extends {TitlePage}
 * @extends {CreditCardStyle}
 * @extends {Wrapper}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const ModalPayment = styled.div`
  padding: 1rem;

  ${TitlePage} {
    display: none;
  }

  ${CreditCardStyle} {
    flex-wrap: wrap;

    & > ${Wrapper} {
      width: 31%;
      min-width: 31%;

      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
      }
    }

    ${ContainerNumberCard} {
      width: 31%;
      min-width: 31%;

      ${Wrapper} {
        width: 100%;
      }

      ${ListCards} {
        padding: 0;
      }

      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  ${PaymentItemContent} {
    ${Col} {
      padding: 0;
      margin: 0;

      &.deposit {
        ${Row} {
          flex-wrap: wrap;
          justify-content: space-between;

          ${Col} {
            font-size: 0.875rem;
            width: 240px;
            margin-bottom: 1rem;

            ${Label} {
              padding: 1rem 0.5rem 1rem 4.5rem;
            }
          }
        }
      }
    }
  }

  ${BtnGroup} {
    flex-diretion: row;

    ${Button} {
      background-color: ${colors.primary};
      text-decoration: none;
      color: ${colors.default};

      &:first-of-type {
        background-color: transparent;
        text-decoration: underline;
        color: ${colors.primary};
      }
    }
  }
`;
